.mymnmdl.viewsug.mrdictionary.nmmdl .modal-body{padding: 10px !important;}
.nmbtn{margin-right: 10px !important;background-color: #5b47fb !important;
  color: #fff;
  border: none;}
  .nmbtn:hover{margin-right: 10px !important;background-color: #5b47fb !important;
    color: #fff;
    border: none;}
.mymnmdl.viewsug.mrdictionary.mymnmdl .modal-header{
  justify-content: space-between !important;
  padding: 10px !important;
}
.beltimg{width: 100%;
  height: 100vh;
  visibility: hidden;}
.mndatry{display:flex; align-items: center;}
.mndatry label{display:flex;}
.mndatry input{margin-right: 5px;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiBox-root.css-1p0wbhh{display: none !important;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiTableContainer-root{height:100% !important;}
.syncsv{visibility: hidden;}
.masters-material-table tr th{border-right: 1px solid rgba(224, 224, 224, 1);}
.masters-material-table tr td{border-right: 1px solid rgba(224, 224, 224, 1);}
.nmsvntns{display: flex;align-items: center;justify-content: center;margin: 0 auto;margin-top: 10px;}
.nmsvntns .nmbtn{margin-right: 30px !important;}
.nmsvntnsview{display: flex;align-items: center;justify-content: center;margin: 0 auto;margin-top: 10px;margin-bottom: 15px !important;}
.pdqcmro.masters-material-table.nmmodifiertemplist tr td{border-right: none !important;padding: 0.3rem !important;}
.pdqcmro.masters-material-table.nmmodifiertemplist .MuiTableContainer-root {
  height: calc(100vh - 225px) !important;
}
.nounModifierTemplateData{
  height: 100% !important;
}
.nmlnk{text-decoration: underline;}
.production-update-main.vewnm{padding: 25px !important;}
.production-update-main.vewnm .form-control.myfrm{background-color: #dddddd4f !important;};
.production-update-main.vewnm .resizable-textfield .MuiInputBase-root{background-color: #dddddd4f !important;};
.searchWrapper {
    border: none !important;
}
.maxHeight-n-scroller {
    .searchWrapper {
        max-height: 100px;
        overflow-x: hidden;
    }
}
.searchWrapper,
.multiSelectContainer ul {
    background-color: #fff;
    box-shadow: none;
}
.custom-auto-select {
    input:focus::placeholder {
        color: transparent !important;
    }
}

.float-visible-select,
.float-select:focus-within {
    .css-1jqq78o-placeholder {
        display: none;
    }
}

.react-select-container-list{height:42px;}
.react-select-container-list{height:42px;}

.createnm{display: flex;align-items: center;}

.css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1c273c !important;
}
.float-visible-select > label, .float-visible-select:focus-within > label, .float-select:focus-within > label{opacity: 0.91 !important;}

@media screen and (min-width:1000px) and (max-width:1600px) {
.mrodicttxtarea{max-height: 342px !important;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiTableContainer-root{
    height: 100% !important;
    max-height: calc(100vh - 40px) !important;
    overflow: auto;
  }
.masters-material-table{height:100% !important;}
.pdqcmro.masters-material-table.nmtable .MuiTableContainer-root {
  height:100% !important;
  max-height: calc(50vh - 250px) !important;
  overflow: auto;
}
.pdqcmro.masters-material-table.nmtable.mrodicttble .MuiTableContainer-root {
  height: 100% !important;
  max-height: 145px !important;
  overflow: auto;
}
.pdqcmro.masters-material-table.nmtable.unspcimg.mrodictvewer .MuiTableContainer-root {
  height: 100% !important;
  max-height: 308px !important;
  overflow: auto;
}
.pdqcmro.masters-material-table{height:100% !important;}
.pdqcmro.masters-material-table.upldmrodiction .MuiTableContainer-root.css-rfwhd3-MuiTableContainer-root{max-height: calc(100vh - 525px) !important;}
.pdqcmro.masters-material-table.nmtable.createnmtemp .MuiTableContainer-root{max-height: calc(100vh - 515px) !important;}
}
.form-control.myfrm{background-color: #dddddd4f !important;-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  padding-right: 10px; }
.react-select-container-list.form-control.form.add-basefare-input.myfrm {background-color: #dddddd4f !important;}
.production-update-main.editnmtemplate{overflow-x: hidden !important;}
.production-update-main.nmlist-main{height:100% !important;}
.nmtemplatelist.mrodictvew .resizable-textfield .MuiInputBase-root{
    background: #fff !important;
}
.pdqcmro.masters-material-table.nmtable.unspcimg.mrodictvewer{
  height: auto !important;
}
.pdqcmro.masters-material-table.nmtable.unspcimg.mrodictvewer .MuiTableContainer-root{
  height: auto !important;
  max-height: calc(100vh - 295px) !important;
  overflow: auto;
}

.production-update-main.vewnm.mrodictvew{
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

.production-update-main.vewnm.mrodictvew .MuiBox-root.css-1tbggly{display: none;}
.resizable-textfield.myfrm.nmdefin .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
  min-height: 45px !important;
  height:45px !important;
}
.resizable-textfield.myfrm.nmdefin .MuiInputBase-root{
  min-height:45px !important;
}
.resizable-textfield.myfrm.nmdefin{
   height:45px;
}
.pdqcmro.masters-material-table.nmtable.mrodicttble .Mui-TableHeadCell-Content-Wrapper.MuiBox-root.css-lapokc{white-space: nowrap !important;}
.pdqcmro.masters-material-table.nmtable.mrodicttble .Mui-TableHeadCell-Content-Wrapper.MuiBox-root.css-1t5kuvk{white-space: nowrap !important;}

.nmtemplatelist.mrodictvew.row .masters-material-table tr td {
  padding: 0.3rem !important;
}
.nmtemplatelist.mrodictvew.row .masters-material-table tr th {
  padding: 0.3rem !important;
}
.mrodicttxtarea{max-height: 375px;}
.nmtemplatelist.mrodictvew .resizable-textfield .MuiInputBase-root{min-height: 87px !important;height:87px !important;}
.mrodicttxtarea textarea{min-height: 75px !important;height:75px !important;}

.pdqcmro.masters-material-table {
  height: 100% !important;
}

@media screen and (min-width:1900px) and (max-width:2400px){
.mrodicttxtarea textarea{
  min-height: 120px !important;
  height:75px !important;
}

  .pdqcmro.masters-material-table.nmtable .MuiTableContainer-root {    
    height: calc(50vh - 310px) !important;
    overflow: auto;
  }
  .pdqcmro.masters-material-table.nmtable.mrodicttble{height:auto !important;}
  .pdqcmro.masters-material-table.nmtable.mrodicttble .MuiTableContainer-root{max-height: 245px !important;overflow: auto;height:100% !important;}
  .mrodicttxtarea {
    max-height: 485px;
 }
.nmtemplatelist.mrodictvew .resizable-textfield .MuiInputBase-root {
  min-height: 138px !important;
  height: 138px !important;
}
.pdqcmro.masters-material-table .MuiPaper-root.MuiPaper-elevation{max-height: 485px !important;overflow: auto;}
.pdqcmro.masters-material-table {
  height: 100%;
}
.pdqcmro.masters-material-table.nmmodifiertemplist .MuiPaper-root.MuiPaper-elevation{max-height: 100% !important;overflow: auto;}
.pdqcmro masters-material-table.nmmodifiertemplist.lstnm .MuiTableContainer-root.css-rfwhd3-MuiTableContainer-root{max-height: calc(100vh - 210px) !important;overflow: auto;height:100% !important;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiPaper-root.MuiPaper-elevation{max-height: 100% !important;overflow: auto;}
.pdqcmro.masters-material-table.nmtable.unspcimg.mrodictvewer .MuiTableContainer-root {
  height: auto !important;
  max-height: calc(100vh - 280px) !important;
  overflow: auto;
}
.pdqcmro.masters-material-table.nmtable.mrodicttble.atrvles .MuiTableContainer-root.css-1jv9xuu-MuiTableContainer-root{
  max-height: 198px !important;
}
.pdqcmro masters-material-table.nmtable.mrodicttble.unspscclmns .MuiTableContainer-root.css-1jv9xuu-MuiTableContainer-root{
  max-height: 185px !important;
  overflow: auto;
}
}
.css-rfwhd3-MuiTableContainer-root{height:100% !important;}

.MuiPagination-ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiPaginationItem-root {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 4px;
}

.MuiPaginationItem-page {
  color: #5B47FB;
}

.MuiPaginationItem-ellipsis {
  cursor: default;
}

.MuiPaginationItem-icon {
  color: #5B47FB;
}

.pdqcmro.masters-material-table.nmmodifiertemplist .css-10gei56{display: block !important;}
.pdqcmro.masters-material-table.nmmodifiertemplist .MuiTableContainer-root {
  height: 100% !important;max-height: 685px !important;min-height: 685px !important;
}
.MuiTablePagination-root.MuiBox-root.css-8k4lth .MuiPagination-root.MuiPagination-outlined.css-10qzu17-MuiPagination-root{margin-top: 0px !important;}
.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root{background-color: #D5E1F5 !important;color:#fff !important;}
.production-update-main.vewnm.viewnounmodifiertemplate{padding-bottom: 0px !important;}
.production-update-main.vewnm.viewnounmodifiertemplate .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.MuiInputBase-inputMultiline.MuiInputBase-inputSizeSmall {height:80px !important;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiTableContainer-root{max-height: calc(100vh - 345px) !important;}
.pdqcmro.masters-material-table tr th{border:1px solid #e0e0e0 !important;}
.pdqcmro.masters-material-table.nmmodifiertemplist tr td{border:1px solid #e0e0e0 !important;border-bottom: none !important;border-right: none !important;}
.cMuiBox-root.css-10gei56 {
  min-height: 4.5rem !important;
}
.masters-material-table tr th{background-color:#d5e1f5 !important;}
.pdqcmro.masters-material-table.nmmodifiertemplist .css-10gei56{min-height: 55px !important;}
@media screen and (min-width:1000px) and (max-width:1600px){
  .pdqcmro.masters-material-table.nmmodifiertemplist .MuiTableContainer-root {
    height: 100% !important;max-height: 370px !important;min-height: 370px !important;
  }
}
.pdqcmro masters-material-table.nmmodifiertemplist .MuiBox-root.css-1llu0od{padding: 0px !important;}