//1376 resolution
@media screen and (min-width: 1280px) and (max-width: 1366px) {
  .active-tasks-table-height {
    height: 450px;
  }

  .active-resources-table-height {
    height: 380px;
  }

  .duration-table-height {
    height: 390px;
  }
}

//1400 resolution
@media screen and (min-width: 1399px) and (max-width: 1439px) {
  .active-tasks-table-height {
    height: 800px;
  }

  .active-resources-table-height {
    height: 760px;
  }

  .duration-table-height {
    height: 770px;
  }
}

//1440 resolution
@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .active-tasks-table-height {
    height: 660px;
  }

  .active-resources-table-height {
    height: 610px;
  }

  .duration-table-height {
    height: 620px;
  }
}

//1600 resolution
@media screen and (min-width: 1600px) and (max-width: 1679px) {
  .active-tasks-table-height {
    height: 650px;
  }

  .active-resources-table-height {
    height: 600px;
  }

  .duration-table-height {
    height: 610px;
  }
}

//1680 resolution
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .active-tasks-table-height {
    height: 800px;
  }

  .active-resources-table-height {
    height: 760px;
  }

  .duration-table-height {
    height: 770px;
  }
}

//1920 resolution
@media screen and (min-width: 1920px) and (max-width: 2111px) {
  .active-tasks-table-height {
    height:740px;
  }

  .active-resources-table-height {
    height: 700px;
  }

  .duration-table-height {
    height: 700px;
  }
}
